





































































































@import '@design';

.home-hero {
  position: relative;
}

.v-card.hero-cta {
  background-color: transparent;
}
.hero-title.header-4 {
  // TODO (ESS) : dont hard code
  padding-bottom: 0.15em;
  margin: 0 0 0.3em;
  text-transform: uppercase;
}

.hero-text {
  // TODO (ESS) : dont hard code
  margin-bottom: 1em;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.034em;

  @media only screen and (max-width: 414px) {
    font-size: 1.3rem;
  }
}

.hero-hours {
  @media only screen and (min-width: 600px) {
    white-space: pre;
  }
}
